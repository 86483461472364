import React from 'react';
import { Popup } from '@partssourceinc/react-ui-core';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';

const ForgotPasswordIntegrateUserDialog = ({onConfirm}) => {

    return (
        <Popup
            confirmText="Done"
            cancelText={null}
            show={true}
            hideButtons={false}
            onConfirm={onConfirm}
            onCancel={onConfirm}
            loading={false}
        >
            <div style={{textAlign: 'center'}}>
                <img style={{width: '40px', marginBottom: '20px'}} src="images/icn_orange-exclamation@2x.png" />
                <h1 className="title">Unable to Reset Password</h1>
                <div> We are unable to reset your password due to your integration, please login through your punchout - Should you need assistance, please contact our Sales Support Team at 
                    <span style={{color: '#005da6', fontWeight: '600'}}> <a href={'tel:+1' + defaultPhoneNumber}>{defaultPhoneNumber}</a></span> 
                </div>
            </div>

        </Popup>
    );

}

export default ForgotPasswordIntegrateUserDialog;
