import _ from 'lodash';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {NavLink} from 'react-router-dom';

import {Button, TextField} from '@partssourceinc/react-ui-core';
import PageMetaData from 'components/PageMetaData';
import * as NetworkStore from 'stores/Network';
import ForgotPasswordIntegrateUserDialog from 'components/ForgotPasswordIntegrateUserDialog';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';

import 'less/login.less';

@withRouter
@connect(() => ({}), NetworkStore.actionCreators)
export default class ForgotPassword extends React.Component {
    static propTypes = NetworkStore.ActionShape;
    
    constructor(props) {
        super(props);
        this.state = {
            firstLoad: true,
            errorMessage: '',
            step: 1,
            errors: {},
            touched: {email: false},
            email: '',
            showIntegratedDialog: false,
        };
    }

    validate(values) {
        let errors = {};

        this.setState({firstLoad: false});

        if (!values.email) {
            errors.email = 'Email is required';
        } 
    
        return errors;
    }

    updateErrors(values) {
        const errors = this.validate(values);
        this.setState({errors});
        return Object.keys(errors).length;
    }

    displayErrors(currentField = '') {
        const {touched: alreadyTouched, ...otherState} = this.state;
        const errors = this.validate(this.state);
        let touched = _.mapValues(alreadyTouched, (v, k) => !!(alreadyTouched[k] || otherState[k] || k === currentField));

        this.setState({errors, touched});
        return Object.keys(errors).length;
    }

    sendEmail(e) {
        e.preventDefault();
        const values = this.state;
        const {forgotPassword} = this.props;

        forgotPassword(values).then(({response: {status, data}}) => {
            if (data.result === 0) { // success
                this.setState({step: 2});
            } else { // fail
                if (data.result === 1) {
                    this.setState({errorMessage: 'We are having difficulty accessing your account. Please call ' + defaultPhoneNumber + ' and ask to speak with the eCommerce team.'});
                } else if (data.result === 2) {
                    this.setState({errorMessage: 'Something went wrong'});
                } else if (data.result === 3) {
                    this.setState({errorMessage: 'Multiple contacts found for email'});
                } else if (data.result === 5) { // integrated
                    this.setState({showIntegratedDialog: true});
                }

                this.setState({step: 3});
            }            
        
        });
    }

    handleFieldChange(field, e) {
        let newState = {...this.state, [field]: e.target.value};
        this.setState(newState);
        this.updateErrors(newState);
    }

    onConfirmDialog() {
        this.setState({showIntegratedDialog: false, step: 1, email: ''}); 
    }

    componentDidUpdate() {
        const {step} = this.state;
        
        if (step === 3) {
            let errorHeading = document.getElementsByClassName('error-message-heading')[0];
    
            let indexOfNumber = errorHeading.innerHTML.indexOf(defaultPhoneNumber);
            let newText = '<a href="tel:+1' + defaultPhoneNumber + '">' + defaultPhoneNumber + '</a>';
    
            if (indexOfNumber !== -1) {
                errorHeading.innerHTML = errorHeading.innerHTML.replace(defaultPhoneNumber, newText);
            }
        }
    }
 
    render() {
        const {errors, touched, email, firstLoad, step, errorMessage, showIntegratedDialog} = this.state;

        return (<div className="login-form" >
            <PageMetaData title="Forgot Password" />

            {step === 1
                ? <div className="login-content" style={{marginBottom: '30px'}}>
                    <h1>Forgot Password</h1>
                    <form onSubmit={::this.sendEmail}>
                        <div>
                            <TextField type="text" id="email" autoFocus={true} tabIndex="1" value={email} onChange={e => ::this.handleFieldChange('email', e)} onBlur={() => ::this.displayErrors('email')} showErrorMessage={errors.email && touched.email}
                                placeholder="Email or user Id" label="Email or user Id" />
            
                            <Button disabled={firstLoad || Object.keys(errors).length > 0} secondary={true} type="submit">Send</Button>
                        </div>
                    </form>
                </div> : '' }

            {step === 2
                ? <div className="login-content" style={{marginBottom: '30px'}}>
                    <h1 style={{marginBottom: '25px'}}>The information has been sent</h1>
                    <NavLink style={{marginRight: '10px', marginTop: '35px', marginBottom: '30px'}} to="/" className="pull-left forgot-username">Return to Homepage</NavLink> 
                </div>
                : ''}

            {step === 3
                ? <div className="login-content" style={{marginBottom: '30px'}}>
                    <h1 className="error-message-heading" style={{marginBottom: '25px'}}>{errorMessage}</h1>
                    <NavLink onClick={() => this.setState({step: 1, firstLoad: true})} style={{marginRight: '10px', marginTop: '35px', marginBottom: '30px'}} to="/forgot" className="pull-left forgot-username">Please try again</NavLink> 
                </div>
                : ''}
            { showIntegratedDialog && <ForgotPasswordIntegrateUserDialog onConfirm={::this.onConfirmDialog} /> }
        </div>);
    }
}
